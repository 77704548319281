import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import ColorList from 'components/ColorList';

export default function ColorListPage(props) {
  let {
    data: { allContentfulProductColor, allShopifyProduct },
  } = props;

  return (
    <Layout>
      <ColorList
        colors={allContentfulProductColor.nodes}
        allShopifyProduct={allShopifyProduct.nodes}
      />
    </Layout>
  );
}

export const query = graphql`
  query {
    allContentfulProductColor {
      nodes {
        ...ContentfulProductColorFields
      }
    }
    allShopifyProduct {
      nodes {
        ...ShopifyProductFields
      }
    }
  }
`;
