import React, { useState, useEffect } from 'react';
import { getProductUrl } from 'helpers/url';

import {
  Spacing,
  SharedStyles,
  Container,
  Filter,
  ProductGrid,
} from '@matchbox-mobile/kenra-storybook';
import get from 'lodash.get';

const { StPageTitleBar } = SharedStyles;
const Filters = {
  All: 'all',
  Permanent: 'Kenra Color Permanent',
  Demi: 'Kenra Color Demi',
  Semi: 'Semi Permanent',
  Blonde: 'Simply Blonde',
  StudioStylist: 'Studio Stylist Express',
};

const Categories = {
  Permanent: 'Permanent',
  Demi: 'Demi-Permanent',
  Semi: 'Semi-Permanent',
  Blonde: 'Simply Blonde',
  StudioStylist: 'Studio Stylist Express',
};

export const UrlFiltersEnum = {
  PERMANENT: 'permanent',
  DEMI: 'demi-permanent',
  SEMI: 'semi-permanent',
  BLONDE: 'simply-blonde',
  STUDIO_STYLIST: 'studio-stylist',
};

function getColorCategory(shopifyProductTags) {
  if (shopifyProductTags.includes(Filters.Permanent)) {
    return Categories.Permanent;
  } else if (shopifyProductTags.includes(Filters.Demi)) {
    return Categories.Demi;
  } else if (shopifyProductTags.includes(Filters.Semi)) {
    return Categories.Semi;
  } else if (shopifyProductTags.includes(Filters.Blonde)) {
    return Categories.Blonde;
  } else if (shopifyProductTags.includes(Filters.StudioStylist)) {
    return Categories.StudioStylist;
  } else {
    return null;
  }
}

export default function ColorList({ colors, allShopifyProduct }) {
  const [currentFilter, setCurrentFilter] = useState();

  useEffect(() => {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    if (url.split('?filter=').length > 1) {
      const type = url.split('?filter=')[1];
      let filterType = '';
      switch (type) {
        case UrlFiltersEnum.PERMANENT:
          filterType = Filters.Permanent;
          break;
        case UrlFiltersEnum.DEMI:
          filterType = Filters.Demi;
          break;
        case UrlFiltersEnum.SEMI:
          filterType = Filters.Semi;
          break;
        case UrlFiltersEnum.BLONDE:
          filterType = Filters.Blonde;
          break;
        case UrlFiltersEnum.STUDIO_STYLIST:
          filterType = Filters.StudioStylist;
          break;
        default:
          filterType = Filters.All;
      }
      setCurrentFilter(filterType);
    } else {
      setCurrentFilter(Filters.All);
    }
  }, []);

  let colorProducts = allShopifyProduct.filter(product => {
    if (product.tags && product.tags.includes('Kenra Color')) {
      return true;
    } else {
      return false;
    }
  });
  let filteredProducts =
    currentFilter !== Filters.All
      ? colorProducts.filter(product => {
          if (product.tags && product.tags.includes(currentFilter)) {
            return true;
          } else {
            return false;
          }
        })
      : colorProducts;
  return (
    <>
      <Spacing removeSpaceTop removeSpaceTopMobile removeSpaceBottomMobile>
        <StPageTitleBar>
          <Container>
            <h1>Kenra Color</h1>
            <p>
              Straightforward and easy to use haircolor line produces
              consistent,
              <br />
              reliable results that stylists can count on
            </p>
          </Container>
        </StPageTitleBar>
      </Spacing>

      <Spacing removeSpaceTop removeSpaceBottomMobile>
        <Filter
          items={[
            {
              text: 'View all',
              onClick: () => setCurrentFilter(Filters.All),
              isActive: currentFilter === Filters.All,
            },
            {
              text: 'PERMANENT',
              onClick: () => setCurrentFilter(Filters.Permanent),
              isActive: currentFilter === Filters.Permanent,
            },
            {
              text: 'DEMI-PERMANENT',
              onClick: () => setCurrentFilter(Filters.Demi),
              isActive: currentFilter === Filters.Demi,
            },
            // {
            //   text: 'SEMI-PERMANENT',
            //   onClick: () => setCurrentFilter(Filters.Semi),
            //   isActive: currentFilter === Filters.Semi,
            // },
            {
              text: 'SIMPLY BLONDE',
              onClick: () => setCurrentFilter(Filters.Blonde),
              isActive: currentFilter === Filters.Blonde,
            },
            {
              text: 'STUDIO STYLIST',
              onClick: () => setCurrentFilter(Filters.StudioStylist),
              isActive: currentFilter === Filters.StudioStylist,
            },
          ]}
        />
      </Spacing>

      <Spacing removeSpaceTop removeSpaceBottom>
        <ProductGrid
          items={filteredProducts.map(color => {
            let handle = get(color, 'handle');
            let subTitle;
            let isLight = false;
            let bg = get(color, 'images[1].localFile.publicURL'); // get(color, 'hairImage.localFile.publicURL')
            let img = get(color, 'images[0].localFile.thumbnail.resize.src');

            // check if there is a product in contentful to override bg or subtitle with
            let contentfulProduct = colors.find(
              p => handle === get(p, 'product.handle')
            );
            if (contentfulProduct) {
              const overrideBg = get(
                contentfulProduct,
                'hairImage.localFile.publicURL'
              );
              if (overrideBg) {
                bg = overrideBg;
              }
              subTitle = contentfulProduct.title;
              isLight = get(contentfulProduct, 'hairImageIsLight');
            }

            return {
              type: getColorCategory(get(color, 'tags')),
              title: get(color, 'title'),
              bg,
              img,
              subTitle,
              href: getProductUrl(handle),
              isLight,
            };
          })}
        />
      </Spacing>
    </>
  );
}
